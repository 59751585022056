body.woocommerce{
	.main {
		.page-header{
			margin: 0;
		    background: #ccc;
		    border-bottom: 4px solid #bbb;
		    padding: 15px 15px 0;
		    letter-spacing: .02em;
		    border-top-left-radius: 2px;
		    border-top-right-radius: 2px;
		    box-shadow: 0 1px 5px rgba(0,0,0,.2);
		    height:74px;
		    h1{
		    	color: #333;
		    	letter-spacing: .02em;
		    	margin:10px 0;
		    }
		    a{
			    text-transform:uppercase;
				font-size:.85em;
				box-shadow: 0 -2px rgba(0,0,0,.25) inset;
				margin-top:3px;
			}
		}
		.main-content{
			background-color:#fff;
			border-radius: 2px;
	    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
	    	padding: 15px;
			margin-bottom:30px;
			.woocommerce-breadcrumb{
				display:none;
			}
			.woocommerce-result-count{
				display:none;
			}
			.woocommerce-ordering{
				display:none;
			}
			.product{
				.onsale{
					display:none;
				}
				h3{
					font-size:28px;
					padding:0;
				}
				.star-rating{
					display:none;
				}
				.price{
					color:$brand-primary;
					del,ins{
						display:inline-block;
					}
				}
				.add_to_cart_button{
					@include button-variant($btn-blue-color, $btn-blue-bg, $btn-blue-border);
					text-transform:uppercase;
					font-size:.85em;
					box-shadow: 0 -2px rgba(0,0,0,.25) inset;
					margin-top:3px;
				}
				.product_meta{
					display:none;
				}
				.woocommerce-tabs{
					display:none;
				}
				.related.products{
					display:none;
				}
			}
			> .product{
				@include make-row;
				> .images{
					@include make-sm-column(6);
				}
				> .summary{
					@include make-sm-column(6);
					.qty{
						height:36px;
						padding:4px 8px;
						width:54px;
					}
					.input-group-btn{
						width:100%;
						.single_add_to_cart_button{
							@include button-variant($btn-blue-color, $btn-blue-bg, $btn-blue-border);
							text-transform:uppercase;
							font-size:16px;
						}
					}
				}
			}
			nav.woocommerce-pagination{
				ul.page-numbers{
					@include pagination-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $border-radius-large);
					border-radius:$border-radius-large;
					li:last-of-type{
						border-radius:$border-radius-large;
					}
				}
			}
			.alert{
				a.button{
					@extend .pull-right;
					@include button-variant($btn-gray-color, $btn-gray-bg, $btn-gray-border);
					text-transform:uppercase;
					font-size:.85em;
					box-shadow: 0 -2px rgba(0,0,0,.25) inset;
					margin-top:3px;
				}
			}
		}
	}
}

body.woocommerce-cart{
	.table.cart{
		>thead>tr>th{
			background:#eee;
		}
		.qty{
			height:36px;
			padding:4px 8px;
			width:54px;
		}
		.actions input[type=submit]{
			@include button-variant($btn-gray-color, $btn-gray-bg, $btn-gray-border);
			text-transform:uppercase;
			font-size:.85em;
			box-shadow: 0 -2px rgba(0,0,0,.25) inset;
			margin-top:3px;
		}
	}
	.cart-collaterals .cart_totals{
		h2{
			color:$brand-primary;
			margin:0;
		}
		table td span.amount{
			@extend .pull-right;
		}
		.wc-proceed-to-checkout{
			a.button{
				@include button-variant($btn-blue-color, $btn-blue-bg, $btn-blue-border);
				text-transform:uppercase;
				font-size:.85em;
				box-shadow: 0 -2px rgba(0,0,0,.25) inset;
				margin-top:3px;
			}
		}
	}
	.addtoany_share_save_container{
		display:none;
	}
}

body.woocommerce-checkout{
	.input-text{
		@extend .form-control;
	}
	.select2-container .select2-choice{
		padding:4px 8px;
	}
	.woocommerce-checkout-review-order-table>thead>tr>th{
		background:#eee;
	}
	#payment{
		@extend .panel;
		@extend .panel-default;
		ul.payment_methods li input{
			margin:4px 0 0;
			position: relative;
		}
		#place_order{
			@include button-variant($btn-blue-color, $btn-blue-bg, $btn-blue-border);
			text-transform:uppercase;
			font-size:.85em;
			box-shadow: 0 -2px rgba(0,0,0,.25) inset;
			margin-top:3px;
		}
	}
	.addtoany_share_save_container{
		display:none;
	}
}

