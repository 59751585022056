.category{
	.main{
		.page-header{
			margin: 0;
		    background: #ccc;
		    border-bottom: 4px solid #bbb;
		    padding: 15px 15px 0;
		    letter-spacing: .02em;
		    border-top-left-radius: 2px;
		    border-top-right-radius: 2px;
		    box-shadow: 0 1px 5px rgba(0,0,0,.2);
		    height:74px;
		    h1{
		    	color: #333;
		    	letter-spacing: .02em;
		    	margin:10px 0;
		    }
		}
		.main-content{
			background-color:#fff;
			border-radius: 2px;
	    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
	    	padding: 15px;
			margin-bottom:30px;
			.sticky-posts{
		    	margin-bottom:30px;
		    	border-bottom:2px solid #aaa;
			}
			p{
				border-bottom:1px solid #ccc;
				padding-bottom:10px;
			}
			p:last-of-type{
				border-bottom:0px;
			}
		}
	}
}