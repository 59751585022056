body.single{
	.main article{
		header{
			margin: 0;
		    background: #ccc;
		    border-bottom: 4px solid #bbb;
		    padding: 15px 15px 0;
		    letter-spacing: .02em;
		    border-top-left-radius: 2px;
		    border-top-right-radius: 2px;
		    box-shadow: 0 1px 5px rgba(0,0,0,.2);
		    h1{
		    	color: #333;
		    	letter-spacing: .02em;
		    	margin:10px 0;
		    }
		}
		.entry-content{
			background-color:#fff;
			border-radius: 2px;
	    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
	    	padding: 15px;
			margin-bottom:30px;
			blockquote{
				padding:8px 12px 0;
				display:block;
				font-size:16px;
				border-left:5px solid #ddd;
				line-height:1.5em;
				position:relative;
				margin-top:0;
				margin-bottom:14px;
				&:before{
					color:#ccc;
					content: "\f10d";
					position:relative;
					left:0;
					top:0;
					margin-right:6px;
					float:left;
					display: inline-block;
				    font: normal normal normal 14px/1 FontAwesome;
				    font-size: 1.33333333em;
				    line-height: .75em;
				    vertical-align: -15%;
				    text-rendering: auto;
				    -webkit-font-smoothing: antialiased;
				    -moz-osx-font-smoothing: grayscale;
				}
			}
		}
	}
}