.comment-list {
 	@include list-unstyled;
}
.comment-list ol {
 	list-style: none;
}
.comment-form p {
 	@extend .form-group;
}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
 	@extend .form-control;
 	background-color:#efefef;
}
.comment-form input[type="submit"] {
	@extend .btn;
	@extend .btn-secondary;
	display:block;
	margin-left:auto;
	text-transform:uppercase;
	font-size:.75em;
	box-shadow: 0 -2px rgba(0,0,0,.25) inset;
}

.comment-form p.form-submit{
	float:right;
	margin-bottom:0;
}

.comment-form p#gasp_p{
	float:left;
	clear:none!important;
	margin-bottom:0;
}

.comment-form span.required{
	color:$brand-primary;
	font-weight:bold;
}

.comment-respond{
	margin-bottom:30px;
	h3{
		margin: 0;
	    background: #444;
	    border-bottom: 4px solid #333;
	    color: #fff;
	    padding: 15px 15px 0;
	    letter-spacing: .02em;
	    border-top-left-radius: 2px;
	    border-top-right-radius: 2px;
	    box-shadow: 0 1px 5px rgba(0,0,0,.2);
	    font-size:2.25em;
	}
	h3.comment-reply-title {
		> a {
			color:#e0e0e0;
		}
		a#cancel-comment-reply-link{
			@extend .btn;
			@extend .btn-secondary;
			text-transform:uppercase;
			font-size:.6em;
			box-shadow: 0 -2px rgba(0,0,0,.25) inset;
			margin-top:-4px;
			font-family:$font-family-sans-serif;
			float:right;
			letter-spacing:0;
		}
	}
	form{
		background-color:#fff;
		border-radius: 2px;
    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
    	padding: 15px;
		@extend .clearfix;
    	.fa-li{
    		line-height:22px;
    	}
	}
}

.comments{
	> h2{
		margin: 0;
	    background: #444;
	    border-bottom: 4px solid #333;
	    color: #fff;
	    padding: 15px 15px 0;
	    letter-spacing: .02em;
	    border-top-left-radius: 2px;
	    border-top-right-radius: 2px;
	    box-shadow: 0 1px 5px rgba(0,0,0,.2);
	    font-size:2.25em;
	}
	> p{
		background-color:#fff;
		border-radius: 2px;
    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
    	margin: 0;
    	padding: 15px;
	}
	li{
		.reply a{
			@extend .btn;
			@extend .btn-secondary;
			display:block;
			margin-left:auto;
			text-transform:uppercase;
			font-size:.75em;
			box-shadow: 0 -2px rgba(0,0,0,.25) inset;
			width:85px;
		}
		.children {
			padding-left:15px;
			margin-top:15px;
			li {
				margin-top:15px;
				border:1px solid #ddd;
				padding:15px;
			}
			li.depth-2 {
				border:1px solid #d9d9d9;
				background-color:#f9f9f9;
			}
			li.depth-3 {
				border:1px solid #d0d0d0;
				background-color:#f0f0f0;
			}
			li.depth-4 {
				border:1px solid #c9c9c9;
				background-color:#e9e9e9;
			}
			li.depth-5 {
				border:1px solid #c0c0c0;
				background-color:#e0e0e0;
			}
		}
		article{
			footer.comment-meta{
				@extend .clearfix;
				margin:0 0 15px 0;
				.comment-author{
					float:left;
				}
				.comment-metadata{
					float:right;
				}
			}
		}
	}
	li.depth-1{
		background-color:#fff;
		border-radius: 2px;
    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
    	margin: 30px 0;
    	padding: 15px;
	}
	nav{
		background-color:#fff;
		border-radius: 2px;
    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
    	margin-bottom: 30px;
    	padding: 15px;
    	.pager {
    		margin:0;
    		li>a{
	    		@extend .btn;
				@extend .btn-secondary;
				text-transform:uppercase;
				font-size:.75em;
				box-shadow: 0 -2px rgba(0,0,0,.25) inset;
			}
    	}
	}
}