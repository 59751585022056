footer{
	.footer-top{
		background:#ddd;
		border-top:1px solid #ccc;
		padding-bottom:20px;
		h3{
			color:#333;
			font-size:200%;
			letter-spacing:.02em;
		}
		ul{
			list-style:none;
			margin-bottom:0;
			padding-left:0;
			li:not(:last-of-type){
				border-bottom:1px solid #ccc;
			}
			li{
				padding:6px 10px;
				a{
					color:#111;
					display:block;
					text-transform:uppercase;
					font-size:.75em;
					&:hover{
						color:#000;
						text-decoration: none;
					}
				}
				&:hover{
					background-color:#ccc;
				}
			}
		}
	}
	.footer-bottom{
		background:#333;
		color:#f7f7f7;
		font-size:.75em;
		padding:20px 0;
	}
}