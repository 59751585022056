body > header{
	margin-top:40px;
	> .container{
		margin-bottom:20px;
	}
	a.brand{
		img{
			@extend .img-responsive;
			margin:0 auto;
			width:450px;
		}
	}
}

@media (max-width: $screen-md-max){
	body>header a.brand img{
		width:350px;
	}
}

@media (max-width: $screen-sm-max){
	body>header{
		margin-top:20px;
		> .container{
			margin-bottom:10px;
		}
		a.brand img{
			width:280px;
		}
	}
}