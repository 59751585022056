@font-face {
  font-family: 'GillSansStdBoldExtraCond';
  src: url('#{$headings-font-path}#{$headings-font-name}.eot'); /* IE9 Compat Modes */
  src: url('#{$headings-font-path}#{$headings-font-name}.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('#{$headings-font-path}#{$headings-font-name}.woff') format('woff'), /* Modern Browsers */
       url('#{$headings-font-path}#{$headings-font-name}.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('#{$headings-font-path}#{$headings-font-name}.svg#8ee4437bd9947f4ea8139860f707337f') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  700;
}

body>.outer-wrap{
	background-color:#eee;
	padding-top:20px;
}

a:hover,a:active,a:focus{
	text-decoration:none;
}

.tagline{
	font-style:italic;
	color:black;
	text-align:center;
	font-size:1.5em;
	display:block;
	.accent{
		color:$brand-primary;
	}
}

@media (max-width: $screen-md-max){
	.tagline{
		font-size:1.25em;
	}
}

@media (max-width: $screen-sm-max){
	.tagline{
		font-size:1em;
	}
}