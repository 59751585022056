header > nav.nav-primary{
    background-color:#eee;
    padding-bottom:10px;
    .container-fluid{
        background-color:       $navbar-default-bg;
        box-shadow:             0 1px 3px rgba(0,0,0,0.4);
        margin-bottom:          10px;
        .navbar-nav{
            color: 				$navbar-default-color;
            float:              none;
            margin: 			0 auto;
            padding:            0 15px;
            position:           relative;
            > li {
              > a {
              	color: 			$navbar-default-link-color;
              	display:		block;
              	font-size:		1.125em;
              	font-weight:	bold;
              	letter-spacing: .02em;
                padding-top:    $navbar-padding-vertical;
                padding-bottom: $navbar-padding-vertical;
                padding-left: 	$navbar-padding-horizontal;
                padding-right: 	$navbar-padding-horizontal;
                text-transform:	uppercase;
                &:hover,&:active,&:focus{
                	background-color:	$navbar-default-link-hover-bg;
                	color:				$navbar-default-link-hover-color;
                }
              }
            }
            > li.active {
            	> a {
                    background-color:	$navbar-default-link-active-bg;
            		color: 			$navbar-default-link-active-color;
            	}
            }
            > li.open {
            	> a {
            		background-color:	$navbar-default-bg;
            		&:hover,&:active,&:focus{
            			background-color:	$navbar-default-bg;
            		}
            	}
            	> .dropdown-menu {
            		background-color:	#ddd;
            		border-color:		#d0d0d0;
            		> li {
            			> a {
            				color:				#333;
            				font-size:			1em;
            			}
            		}
            	}
            }
            > .topics {
                display:none;
                margin-top:-1px;
                border-top-left-radius:0;
                border-top-right-radius:0;
                right:0;
                padding:0;
                ul{
                    list-style:none;
                    margin-bottom:0;
                    padding-left:0;
                    li:not(:last-of-type){
                        border-bottom:1px solid #ccc;
                    }
                    li{
                        padding:6px 10px;
                        a{
                            color:#111;
                            display:block;
                            text-transform:uppercase;
                            font-size:.75em;
                            &:hover{
                                color:#000;
                                text-decoration: none;
                            }
                        }
                        &:hover{
                            background-color:#ccc;
                        }
                    }
                }
            }
            > .topics.open{
                display:block;
            }
        }
    }
}

@media (max-width: $screen-md-max){
    header>nav.nav-primary .container-fluid .navbar-nav>li>a{
        font-size:1em;
        padding-top:6px;
        padding-bottom:6px;
        padding-left:12px;
        padding-right:12px;
    }
}

@media (max-width: $screen-sm-max){
    header>nav.nav-primary .container-fluid {
        margin-bottom: 0;
        .navbar-nav>li>a{
            font-size:.85em;
            padding-left:8px;
            padding-right:8px;
            letter-spacing:0;
        }
    }
}

@media (max-width: $screen-xs-max){
    header>nav.nav-primary .container-fluid {
        .navbar-nav{
            padding:0;
            >li{
                >a{
                    font-size:1em;
                    padding-left:15px;
                    padding-right:15px;
                }
                .dropdown-menu{
                    padding:0;
                }
            }
        }
        .navbar-toggle {
            float: none;
            display:block;
            margin:10px auto;
            background-color:#ddd;
            border: 1px solid #777;
            line-height:16px;

            > span {
                color:#555;
                text-transform:uppercase;
                font-weight:900;
            }
        }
        .navbar-collapse{
            padding-right:0;
            padding-left:0;
            border-top:0px solid transparent;
            @include box-shadow(none);
        }
    }
}