body aside.sidebar{
	section.widget{
		background-color:#fff;
		border-radius: 2px;
    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
    	padding: 15px;
    	margin-bottom:30px;
    	img{
    		@extend .img-responsive;
    	}
    	h3{
    		margin:3px 0 6px;
    		color:$brand-primary;
    	}
    	p{
    		line-height:1.5;
    	}
    	p:last-child{
    		margin-bottom:0;
    	}
	}
	section.widget-amazon{
		img{
			margin-bottom:10px;
		}
	}
	section.widget.widget_recent_comments_excerpt{
		ul{
			list-style:none;
			margin-bottom:0;
			padding-left:0;
			li{
				line-height:1.4em;
			}
			li:not(:last-of-type){
				border-bottom:1px solid #e7e7e7;
				margin-bottom:10px;
				padding-bottom:10px;
			}
			blockquote{
				padding:6px 12px;
				margin:0;
				font-size:14px;
				border-left:5px solid #ddd;
				line-height:1.5em;
				position:relative;
				background:#f9f9f9;
				margin-top:8px;
				.fa{
					color:#ccc;
					position:relative;
					left:0;
					top:0;
					margin-right:6px;
				}
			}
		}
	}
	section.widget-follow{
		@extend .clearfix;
		h3{
			float:left;
			margin-right:20px;
			margin-bottom:-5px;
		}
		.textwidget{
			float:right;
			a{
				margin-right:8px;
				img{
					margin-top:-3px;
					max-width:26px;
					display:inline-block;
				}
			}
		}
	}
	section.widget-seeking{
		img{
			margin:0 auto;
		}
	}
	section.widget-links{
		background-color:transparent;
		border-radius: 0;
    	box-shadow: 0 0 0;
    	padding: 0;
    	.col-sm-12{
    		img{
    			border-radius: 2px;
		    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
		    	margin-bottom:30px;
    		}
    	}
    	.col-xs-6{
    		img{
    			border-radius: 2px;
		    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
    		}
    	}
    }

    section.widget-subscribe{
    	#subscribe{
    		@include button-variant($btn-blue-color, $btn-blue-bg, $btn-blue-border);
    		text-transform:uppercase;
			font-size:.85em;
			padding:8px 18px;
			margin-right:10px;
    	}
    	#unsubscribe{
    		@include button-variant($btn-gray-color, $btn-gray-bg, $btn-gray-border);
			text-transform:uppercase;
			font-size:.85em;
			padding:8px 18px;
    	}
    }

		.bsap{
			text-align: center;
			a{
			margin: 0 auto;
			}
		}
}

.widget-9{
	display: none;
}

@media (max-width: $screen-sm-max){
	body aside.sidebar section.widget-subscribe{
    	#subscribe{
    		margin-bottom:10px;
    		width:100%;
    	}
    	#unsubscribe{
    		width:100%;
    	}
    }
}

@media (max-width: $screen-xs-max){
	body aside.sidebar{
		section.widget-ads{
			max-width:360px;
			margin-left:auto;
			margin-right:auto;
		}
		section.widget-subscribe{
	    	#subscribe{
	    		margin-bottom:0;
	    		width:auto;
	    	}
	    	#unsubscribe{
	    		width:auto;
	    	}
	    }
	}
}
