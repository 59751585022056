body.page:not(.home){
	.main{
		.page-header{
			margin: 0;
		    background: #ccc;
		    border-bottom: 4px solid #bbb;
		    padding: 15px 15px 0;
		    letter-spacing: .02em;
		    border-top-left-radius: 2px;
		    border-top-right-radius: 2px;
		    box-shadow: 0 1px 5px rgba(0,0,0,.2);
		    height:74px;
		    h1{
		    	color: #333;
		    	letter-spacing: .02em;
		    	margin:10px 0;
		    }
		}
		.main-content{
			background-color:#fff;
			border-radius: 2px;
	    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
	    	padding: 15px;
			margin-bottom:30px;
			blockquote{
				padding:8px 12px 0;
				display:block;
				font-size:16px;
				border-left:5px solid #ddd;
				line-height:1.5em;
				position:relative;
				margin-top:0;
				margin-bottom:14px;
				&:before{
					color:#ccc;
					content: "\f10d";
					position:relative;
					left:0;
					top:0;
					margin-right:6px;
					float:left;
					display: inline-block;
				    font: normal normal normal 14px/1 FontAwesome;
				    font-size: 1.33333333em;
				    line-height: .75em;
				    vertical-align: -15%;
				    text-rendering: auto;
				    -webkit-font-smoothing: antialiased;
				    -moz-osx-font-smoothing: grayscale;
				}
			}
		}
	}
}

body.home{
	.marriage-insight-main,
	.marriage-insight-bottom{
		@extend .row;
	}
	article{
		background-color:#fff;
		border-radius: 2px;
    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
    	padding: 15px;
		p{
			margin-bottom: 0;
		}
		.read-more {
			margin-top:10px;
			text-align:right;
			a{
				@include button-variant($btn-blue-color, $btn-blue-bg, $btn-blue-border);
				text-transform:uppercase;
				font-size:.75em;
				box-shadow: 0 -2px rgba(0,0,0,.25) inset;
			}
		}
	}
	.marriage-insight-main {
		margin-bottom:30px;
		.mi-head{
			margin: 0;
		    background: #ccc;
		    border-bottom: 4px solid #bbb;
		    padding: 15px 15px 0;
		    letter-spacing: .02em;
		    border-top-left-radius: 2px;
		    border-top-right-radius: 2px;
		    box-shadow: 0 1px 5px rgba(0,0,0,.2);
		    height:74px;
		    h1{
		    	color: #333;
		    	letter-spacing: .02em;
		    	margin:10px 0;
		    }
		    a{
				@include button-variant($btn-blue-color, $btn-blue-bg, $btn-blue-border);
				text-transform:uppercase;
				font-size:.85em;
				box-shadow: 0 -2px rgba(0,0,0,.25) inset;
				margin-top:3px;
			}
		}
		article{
			h2.entry-title{
				margin-top:5px;
				margin-bottom:10px;
				> a {
					padding-top:5px;
				}
			}
			img{
				margin-bottom:15px;
			}
		}
	}
	.marriage-insight-bottom .marriage-insight article .entry-summary{
		min-height:196px;
	}
	.main > p{
		background-color:#fff;
		border-radius: 2px;
    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
    	margin: 30px 0;
    	padding: 15px;
	}
	.addtoany_share_save_container.addtoany_content_bottom{
		display:none;
	}
	.main-ad{
    	img {
    		border-radius: 2px;
	    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
	    	margin-bottom:30px;
    	}
	}
	.bottom-row{
		margin-bottom:30px;
		.col-sm-6{
			h1{
				margin: 0;
			    background: #444;
			    border-bottom: 4px solid #333;
			    color: #fff;
			    padding: 15px 15px 0;
			    letter-spacing: .02em;
			    border-top-left-radius: 2px;
			    border-top-right-radius: 2px;
			    box-shadow: 0 1px 5px rgba(0,0,0,.2);
			}
			article{
				min-height:337px;
			}
		}
		.featured-article{
			h3{
				margin:20px 0 6px;
			}
		}
		.marriage-video{
			h3{
				margin:20px 0 0;
			}
			.video-img{
				position:relative;
				.play{
					position:absolute;
					top:50%;
					width:100%;
					text-align:center;
    				margin-top: -21px;
					a{
						color:#333;
					}
					.fa-play{
						top:2px;
						left:2px;
					}
				}
			}
		}
	}
	.media-feeds{
		margin-bottom:30px;
		.col-md-6{
			h1{
				margin: 0;
			    background: #444;
			    border-bottom: 4px solid #333;
			    color: #fff;
			    padding: 15px 15px 0;
			    letter-spacing: .02em;
			    border-top-left-radius: 2px;
			    border-top-right-radius: 2px;
			    box-shadow: 0 1px 5px rgba(0,0,0,.2);
			}
			.cff-wrapper{
				background-color:#fff;
				border-radius: 2px;
		    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
		    	padding: 15px;
		    	#cff{
		    		.cff-item{
		    			border:0px solid transparent!important;
		    		}
		    		.cff-post-links{
		    			font-size:16px;
		    			a.cff-viewpost-facebook{
		    				@extend .btn;
		    				@include button-variant($btn-blue-color, $btn-blue-bg, $btn-blue-border);
							text-transform:uppercase;
							font-size:.75em;
							box-shadow: 0 -2px rgba(0,0,0,.25) inset;
							margin-top:3px;
							margin-right:12px;
		    			}
			    		.cff-dot{
			    			display:none;
			    		}
			    		a.cff-share-link{
			    			@extend .btn;
			    			@include button-variant($btn-blue-color, $btn-blue-bg, $btn-blue-border);
							text-transform:uppercase;
							font-size:.75em;
							box-shadow: 0 -2px rgba(0,0,0,.25) inset;
							margin-top:3px;
			    		}
		    		}
		    		.cff-expand {
			    		> a {
							text-transform:uppercase;
							font-size:.85em;
							font-weight: 900;
			    		}
			    	}
		    	}
			}
			.twitter-feed{
				background-color:#fff;
				border-radius: 2px;
		    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
		    	padding: 15px;
			}
		}
	}
}

@media (max-width: $screen-md-max){
	body.home .main {
		article img{
			max-width:100%;
			height:auto;
			max-height:206px;
		}
		p > img{
			max-width:100%;
			height:auto;
		}
	}
}

@media (max-width: $screen-sm-max){
	.facebook-feed{
		margin-bottom:30px;
	}
}

@media (max-width: $screen-xs-max){
	body.home .main{
		.marriage-insight-bottom .marriage-insight:first-child{
			margin-bottom:30px;
		}
		article img{
			max-height:100%;
			margin:0 auto;
			display:block;
		}
		.featured-article{
			margin-bottom:30px;
		}
	}
}

body.search{
	.main{
		.page-header{
			margin: 0;
		    background: #ccc;
		    border-bottom: 4px solid #bbb;
		    padding: 15px 15px 0;
		    letter-spacing: .02em;
		    border-top-left-radius: 2px;
		    border-top-right-radius: 2px;
		    box-shadow: 0 1px 5px rgba(0,0,0,.2);
		    height:74px;
		    h1{
		    	color: #333;
		    	letter-spacing: .02em;
		    	margin:10px 0;
		    }
		}
		.main-content{
			background-color:#fff;
			border-radius: 2px;
	    	box-shadow: 0 1px 5px rgba(0,0,0,0.2);
	    	padding: 15px;
			margin-bottom:30px;
			article{
				border-bottom: 2px solid #eee;
				&:last-child{
					border-bottom:0px;
				}
			}
		}
	}
}
